<template>
  <div class="wedding-container">
    <header data-role="Header" class="wedding-header">
      <span class="wedding-text">Marieke &amp; Sven</span>
      <div class="wedding-nav">
        <nav class="wedding-nav1">
          <router-link to="/" class="wedding-text01">Home</router-link>
          <router-link to="/location" class="wedding-navlink">
            Location
          </router-link>
          <router-link to="/wedding" class="wedding-navlink1">
            Wedding
          </router-link>
          <router-link to="/the-team" class="wedding-navlink2">
            The team
          </router-link>
          <router-link to="/additional-info" class="wedding-navlink3">
            Additional Info
          </router-link>
        </nav>
      </div>
      <div class="wedding-btn-group"><app-rsvp></app-rsvp></div>
      <div data-thq="thq-dropdown" class="wedding-dropdown list-item">
        <div data-thq="thq-dropdown-toggle" class="wedding-dropdown-toggle">
          <div data-thq="thq-dropdown-arrow" class="wedding-dropdown-arrow">
            <svg viewBox="0 0 1024 1024" class="wedding-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <ul data-thq="thq-dropdown-list" class="wedding-dropdown-list">
          <li data-thq="thq-dropdown" class="wedding-dropdown1 list-item">
            <router-link to="/">
              <div
                data-thq="thq-dropdown-toggle"
                class="wedding-dropdown-toggle1"
              >
                <span class="wedding-text02">Home</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="wedding-dropdown2 list-item">
            <router-link to="/location">
              <div
                data-thq="thq-dropdown-toggle"
                class="wedding-dropdown-toggle2"
              >
                <span class="wedding-text03">Location</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="wedding-dropdown3 list-item">
            <router-link to="/wedding">
              <div
                data-thq="thq-dropdown-toggle"
                class="wedding-dropdown-toggle3"
              >
                <span class="wedding-text04">Wedding</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="wedding-dropdown4 list-item">
            <router-link to="/additional-info">
              <div
                data-thq="thq-dropdown-toggle"
                class="wedding-dropdown-toggle4"
              >
                <span class="wedding-text05">Additional Info</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="wedding-dropdown5 list-item">
            <router-link to="/the-team">
              <div
                data-thq="thq-dropdown-toggle"
                class="wedding-dropdown-toggle5"
              >
                <span class="wedding-text06">The team</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="wedding-dropdown6 list-item">
            <div data-thq="thq-dropdown-toggle" class="wedding-dropdown-toggle6">
              <app-rsvp rootClassName="rsvp-root-class-name2"></app-rsvp>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="wedding-container1">
      <span class="wedding-text07">
        <span>Welkom vanaf / Begrüßung ab 14:00</span>
        <br />
        <span>Ceremonie / Zeremonie 14:30</span>
        <br />
        <span>Proosten / Sektempfang</span>
        <br />
        <span>Diner / Abendessen</span>
        <br />
        <span>
          Feest tot / Party bis 2:30
          <span v-html="raw5t27"></span>
        </span>
      </span>
    </div>
    <img
      alt="image"
      src="/playground_assets/sven%26marieke%20illustratie-06-300h.png"
      class="wedding-image"
    />
  </div>
</template>

<script>
import AppRsvp from '../components/rsvp'

export default {
  name: 'Wedding',
  components: {
    AppRsvp,
  },
  data() {
    return {
      raw5t27: ' ',
    }
  },
  metaInfo: {
    title: 'Wedding - wedding-page',
    meta: [
      {
        property: 'og:title',
        content: 'Wedding - wedding-page',
      },
    ],
  },
}
</script>

<style scoped>
.wedding-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.wedding-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.wedding-text {
  color: #00a79d;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  font-family: Lobster Two;
  font-weight: 700;
}
.wedding-nav {
  display: flex;
}
.wedding-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.wedding-text01 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.wedding-navlink {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.wedding-navlink1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-color: #00a79d;
  border-width: 0px;
  text-decoration: none;
  border-bottom-width: 2px;
}
.wedding-navlink2 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.wedding-navlink3 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.wedding-btn-group {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  flex-direction: row;
  justify-content: space-between;
}
.wedding-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.wedding-dropdown-arrow {
  transition: 0.3s;
}
.wedding-icon {
  width: 24px;
  height: 24px;
}
.wedding-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.wedding-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.wedding-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.wedding-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.wedding-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.wedding-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.wedding-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.wedding-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.wedding-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.wedding-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.wedding-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.wedding-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.wedding-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.wedding-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.wedding-container1 {
  width: 100%;
  height: 128px;
  display: flex;
  z-index: 100;
  align-items: center;
  border-radius: var(--dl-radius-radius-radius4);
  justify-content: center;
}
.wedding-text07 {
  align-self: center;
  text-align: center;
}
.wedding-image {
  width: 245px;
  height: 237px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .wedding-text {
    color: #00a79d;
    font-style: normal;
    font-weight: 700;
  }
  .wedding-btn-group {
    border-color: var(--dl-color-gray-black);
    border-style: solid;
  }
  .wedding-dropdown {
    display: none;
  }
  .wedding-icon {
    fill: #00a79d;
  }
  .wedding-image {
    height: 198px;
  }
}
@media(max-width: 767px) {
  .wedding-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .wedding-text {
    align-self: stretch;
  }
  .wedding-nav {
    display: none;
  }
  .wedding-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .wedding-text01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .wedding-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .wedding-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .wedding-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .wedding-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .wedding-btn-group {
    display: none;
  }
  .wedding-dropdown {
    width: auto;
    display: inline-block;
  }
  .wedding-dropdown-toggle {
    justify-content: flex-end;
  }
  .wedding-icon {
    fill: #00a79d;
  }
  .wedding-dropdown-list {
    display: none;
    position: static;
    border-color: var(--dl-color-gray-white);
  }
  .wedding-dropdown-toggle1 {
    justify-content: flex-end;
  }
  .wedding-text02 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-dropdown-toggle2 {
    justify-content: flex-end;
  }
  .wedding-text03 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-dropdown-toggle3 {
    justify-content: flex-end;
  }
  .wedding-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-dropdown-toggle4 {
    justify-content: flex-end;
  }
  .wedding-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-dropdown-toggle5 {
    justify-content: flex-end;
  }
  .wedding-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-dropdown-toggle6 {
    justify-content: flex-end;
  }
  .wedding-image {
    height: 179px;
  }
}
@media(max-width: 479px) {
  .wedding-header {
    padding: var(--dl-space-space-unit);
  }
  .wedding-text {
    align-self: stretch;
  }
  .wedding-btn-group {
    display: none;
  }
  .wedding-dropdown-toggle {
    justify-content: flex-end;
  }
  .wedding-icon {
    fill: #00a79d;
  }
  .wedding-dropdown-list {
    display: none;
  }
  .wedding-text02 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-text03 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .wedding-image {
    height: 185px;
  }
}
</style>
