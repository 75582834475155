<template>
  <div class="rsvp-container" v-bind:class="rootClassName">
    <a
      href="mailto:info@marieke-and-sven.com?subject=RSVP"
      class="rsvp-rsvp button"
    >
      {{ RSVP }}
    </a>
  </div>
</template>

<script>
export default {
  name: 'RSVP',
  props: {
    rootClassName: String,
    RSVP: {
      type: String,
      default: 'RSVP',
    },
  },
}
</script>

<style scoped>
.rsvp-container {
  display: flex;
  position: relative;
}
.rsvp-rsvp {
  color: #00a79d;
  font-family: GFS Didot;
  border-color: #00a79d;
  border-width: 2px;
  text-decoration: none;
}





@media(max-width: 479px) {
  .rsvp-container {
    border-color: var(--dl-color-gray-white);
    border-width: 0px;
  }
  .rsvp-rsvp {
    color: #00a79d;
    font-family: GFS Didot;
    border-color: #00a79d;
    border-width: 2px;
  }
}
</style>
