<template>
  <div class="the-team-container">
    <header data-role="Header" class="the-team-header">
      <span class="the-team-text">Marieke &amp; Sven</span>
      <div class="the-team-nav">
        <nav class="the-team-nav1">
          <router-link to="/" class="the-team-text01">Home</router-link>
          <router-link to="/location" class="the-team-navlink">
            Location
          </router-link>
          <router-link to="/wedding" class="the-team-navlink1">
            Wedding
          </router-link>
          <router-link to="/the-team" class="the-team-navlink2">
            The team
          </router-link>
          <router-link to="/additional-info" class="the-team-navlink3">
            Additional Info
          </router-link>
        </nav>
      </div>
      <div class="the-team-btn-group"><app-rsvp></app-rsvp></div>
      <div data-thq="thq-dropdown" class="the-team-dropdown list-item">
        <div data-thq="thq-dropdown-toggle" class="the-team-dropdown-toggle">
          <div data-thq="thq-dropdown-arrow" class="the-team-dropdown-arrow">
            <svg viewBox="0 0 1024 1024" class="the-team-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <ul data-thq="thq-dropdown-list" class="the-team-dropdown-list">
          <li data-thq="thq-dropdown" class="the-team-dropdown1 list-item">
            <router-link to="/">
              <div
                data-thq="thq-dropdown-toggle"
                class="the-team-dropdown-toggle1"
              >
                <span class="the-team-text02">Home</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="the-team-dropdown2 list-item">
            <router-link to="/location">
              <div
                data-thq="thq-dropdown-toggle"
                class="the-team-dropdown-toggle2"
              >
                <span class="the-team-text03">Location</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="the-team-dropdown3 list-item">
            <router-link to="/wedding">
              <div
                data-thq="thq-dropdown-toggle"
                class="the-team-dropdown-toggle3"
              >
                <span class="the-team-text04">Wedding</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="the-team-dropdown4 list-item">
            <router-link to="/additional-info">
              <div
                data-thq="thq-dropdown-toggle"
                class="the-team-dropdown-toggle4"
              >
                <span class="the-team-text05">Additional Info</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="the-team-dropdown5 list-item">
            <router-link to="/the-team">
              <div
                data-thq="thq-dropdown-toggle"
                class="the-team-dropdown-toggle5"
              >
                <span class="the-team-text06">The team</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="the-team-dropdown6 list-item">
            <div data-thq="thq-dropdown-toggle" class="the-team-dropdown-toggle6">
              <app-rsvp rootClassName="rsvp-root-class-name4"></app-rsvp>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="the-team-container1">
      <span class="the-team-text07">
        <span>Toastmasters</span>
        <br />
        <span>
          Voor alle vragen die jullie niet aan ons willen stellen voor- en op 22
          april. Zij weten het antwoord, of hoe te improviseren.
          <span v-html="raw6pno"></span>
        </span>
        <br />
        <span>
          Für alle Fragen, die ihr uns vor und am 22. April nicht stellen wollt,
          koennt ihr euch gerne an unsere Toastmaster wenden. Diese erreicht ihr
          unter:
        </span>
        <br />
        <br />
        <span>Anna Lena Muller (+49 152 03623447)</span>
        <br />
        <span>Steffen Muller (+49 151 21795686)</span>
        <br />
        <span>Koen Bongers (+31 6 38581269)</span>
        <br />
        <br />
        <span>Trouwambtenaar / “Standesbeamter”</span>
        <br />
        <span>
          Pim Bongers
          <span v-html="rawycyt"></span>
        </span>
        <br />
        <br />
        <span>Getuigen / Trauzeugen</span>
        <br />
        <span>Nils Muller &amp; Jens Gabriel</span>
        <br />
        <span>Marijke de Ruiter-Mast &amp; Koen Bongers</span>
      </span>
    </div>
    <div class="the-team-container2">
      <img
        alt="image"
        src="/playground_assets/8e302276-78f4-4b5a-abb7-0cc7b7f9ca15-200h.jpg"
        class="the-team-image"
      />
      <div class="the-team-container3">
        <img
          alt="image"
          src="/playground_assets/img_0756-200h.jpg"
          class="the-team-image01"
        />
        <div class="the-team-container4">
          <img
            alt="image"
            src="/playground_assets/dsc09140-200h.jpg"
            class="the-team-image02"
          />
        </div>
      </div>
    </div>
    <div class="the-team-container5">
      <img
        alt="image"
        src="/playground_assets/d8d1fa2d-e657-400d-9f9f-004feed644cb-200h.jpg"
        class="the-team-image03"
      />
      <img
        alt="image"
        src="/playground_assets/img_3697-200h.jpg"
        class="the-team-image04"
      />
      <img
        alt="image"
        src="/playground_assets/img_0801-200w.jpg"
        class="the-team-image05"
      />
    </div>
    <div class="the-team-container6">
      <div class="the-team-container7">
        <img
          alt="image"
          src="/playground_assets/img_2547-200h.jpg"
          class="the-team-image06"
        />
        <img
          alt="image"
          src="/playground_assets/fullsizerender-200h.jpg"
          class="the-team-image07"
        />
      </div>
      <img
        alt="image"
        src="/playground_assets/img_5485-200h.jpg"
        class="the-team-image08"
      />
    </div>
    <img
      alt="image"
      src="/playground_assets/bloem1-200w.png"
      class="the-team-image09"
    />
  </div>
</template>

<script>
import AppRsvp from '../components/rsvp'

export default {
  name: 'TheTeam',
  components: {
    AppRsvp,
  },
  data() {
    return {
      raw6pno: ' ',
      rawycyt: ' ',
    }
  },
  metaInfo: {
    title: 'The-team - wedding-page',
    meta: [
      {
        property: 'og:title',
        content: 'The-team - wedding-page',
      },
    ],
  },
}
</script>

<style scoped>
.the-team-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.the-team-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.the-team-text {
  color: #00a79d;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  font-family: Lobster Two;
  font-weight: 700;
}
.the-team-nav {
  display: flex;
}
.the-team-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.the-team-text01 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.the-team-navlink {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.the-team-navlink1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-width: 0px;
  text-decoration: none;
}
.the-team-navlink2 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-color: #00a79d;
  border-width: 0px;
  text-decoration: none;
  border-bottom-width: 2px;
}
.the-team-navlink3 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-width: 0px;
  text-decoration: none;
}
.the-team-btn-group {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  flex-direction: row;
  justify-content: space-between;
}
.the-team-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.the-team-dropdown-arrow {
  transition: 0.3s;
}
.the-team-icon {
  width: 24px;
  height: 24px;
}
.the-team-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.the-team-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.the-team-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.the-team-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.the-team-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.the-team-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.the-team-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.the-team-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.the-team-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.the-team-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.the-team-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.the-team-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.the-team-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.the-team-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.the-team-container1 {
  width: 100%;
  height: 306px;
  display: flex;
  align-self: center;
  align-items: flex-start;
  justify-content: center;
}
.the-team-text07 {
  align-self: center;
  text-align: center;
}
.the-team-container2 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-image {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-container3 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-image01 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-container4 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-image02 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-container5 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-image03 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-image04 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-image05 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-container6 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-container7 {
  flex: 0 0 auto;
  width: auto;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.the-team-image06 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-image07 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-image08 {
  width: var(--dl-size-size-large);
  object-fit: cover;
}
.the-team-image09 {
  width: 100px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .the-team-text {
    color: #00a79d;
    font-style: normal;
    font-weight: 700;
  }
  .the-team-btn-group {
    border-color: var(--dl-color-gray-black);
    border-style: solid;
  }
  .the-team-dropdown {
    display: none;
  }
  .the-team-icon {
    fill: #00a79d;
  }
}
@media(max-width: 767px) {
  .the-team-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .the-team-text {
    align-self: stretch;
  }
  .the-team-nav {
    display: none;
  }
  .the-team-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .the-team-text01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .the-team-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .the-team-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .the-team-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .the-team-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .the-team-btn-group {
    display: none;
  }
  .the-team-dropdown {
    width: auto;
    display: inline-block;
  }
  .the-team-dropdown-toggle {
    justify-content: flex-end;
  }
  .the-team-icon {
    fill: #00a79d;
  }
  .the-team-dropdown-list {
    display: none;
    position: static;
    border-color: var(--dl-color-gray-white);
  }
  .the-team-dropdown-toggle1 {
    justify-content: flex-end;
  }
  .the-team-text02 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-dropdown-toggle2 {
    justify-content: flex-end;
  }
  .the-team-text03 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-dropdown-toggle3 {
    justify-content: flex-end;
  }
  .the-team-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-dropdown-toggle4 {
    justify-content: flex-end;
  }
  .the-team-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-dropdown-toggle5 {
    justify-content: flex-end;
  }
  .the-team-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-dropdown-toggle6 {
    justify-content: flex-end;
  }
}
@media(max-width: 479px) {
  .the-team-header {
    padding: var(--dl-space-space-unit);
  }
  .the-team-text {
    align-self: stretch;
  }
  .the-team-btn-group {
    display: none;
  }
  .the-team-dropdown-toggle {
    justify-content: flex-end;
  }
  .the-team-icon {
    fill: #00a79d;
  }
  .the-team-dropdown-list {
    display: none;
  }
  .the-team-text02 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-text03 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .the-team-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
}
</style>
