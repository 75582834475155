import Vue from 'vue'
import Router from 'vue-router'
import Meta from 'vue-meta'

import TheTeam from './views/the-team'
import AdditionalInfo from './views/additional-info'
import Wedding from './views/wedding'
import Location from './views/location'
import Home from './views/home'
import './style.css'

Vue.use(Router)
Vue.use(Meta)
export default new Router({
  mode: 'history',
  routes: [
    {
      name: 'The-team',
      path: '/the-team',
      component: TheTeam,
    },
    {
      name: 'Additional-Info',
      path: '/additional-info',
      component: AdditionalInfo,
    },
    {
      name: 'Wedding',
      path: '/wedding',
      component: Wedding,
    },
    {
      name: 'Location',
      path: '/location',
      component: Location,
    },
    {
      name: 'Home',
      path: '/',
      component: Home,
    },
  ],
})
