<template>
  <div class="home-container">
    <header data-role="Header" class="home-header">
      <span class="home-text">Marieke &amp; Sven</span>
      <div class="home-nav">
        <nav class="home-nav1">
          <router-link to="/" class="home-text01">Home</router-link>
          <router-link to="/location" class="home-navlink">Location</router-link>
          <router-link to="/wedding" class="home-navlink1">Wedding</router-link>
          <router-link to="/the-team" class="home-navlink2">The team</router-link>
          <router-link to="/additional-info" class="home-navlink3">
            Additional Info
          </router-link>
        </nav>
      </div>
      <div class="home-btn-group"><app-rsvp></app-rsvp></div>
      <div data-thq="thq-dropdown" class="home-dropdown list-item">
        <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle">
          <div data-thq="thq-dropdown-arrow" class="home-dropdown-arrow">
            <svg viewBox="0 0 1024 1024" class="home-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <ul data-thq="thq-dropdown-list" class="home-dropdown-list">
          <li data-thq="thq-dropdown" class="home-dropdown1 list-item">
            <router-link to="/">
              <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle1">
                <span class="home-text02">Home</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="home-dropdown2 list-item">
            <router-link to="/location">
              <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle2">
                <span class="home-text03">Location</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="home-dropdown3 list-item">
            <router-link to="/wedding">
              <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle3">
                <span class="home-text04">Wedding</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="home-dropdown4 list-item">
            <router-link to="/additional-info">
              <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle4">
                <span class="home-text05">Additional Info</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="home-dropdown5 list-item">
            <router-link to="/the-team">
              <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle5">
                <span class="home-text06">The team</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="home-dropdown6 list-item">
            <div data-thq="thq-dropdown-toggle" class="home-dropdown-toggle6">
              <app-rsvp rootClassName="rsvp-root-class-name"></app-rsvp>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="home-container1">
      <img
        alt="image"
        src="/playground_assets/voorkant%20kaart-600h.jpg"
        class="home-image"
      />
    </div>
    <div class="home-container2">
      <div class="home-container3"></div>
      <div class="home-container4">
        <div class="home-container5">
          <span class="home-text07">
            <span>
              ... are getting married at 
              <span v-html="raw5ry7"></span>
            </span>
            <br />
          </span>
          <a
            href="https://www.hotelbuiten.nl/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-text10"
          >
            Hotel Buiten.
          </a>
        </div>
        <div class="home-container6">
          <span class="home-text11">
            ... gaan trouwen bij 
            <span v-html="raw255a"></span>
          </span>
          <a
            href="https://www.hotelbuiten.nl/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-text12"
          >
            Hotel Buiten.
          </a>
        </div>
        <div class="home-container7">
          <span class="home-text13">
            ... heiraten bei 
            <span v-html="rawto8q"></span>
          </span>
          <a
            href="https://www.hotelbuiten.nl/"
            target="_blank"
            rel="noreferrer noopener"
            class="home-text14"
          >
            Hotel Buiten.
          </a>
        </div>
      </div>
      <div class="home-container8"></div>
    </div>
  </div>
</template>

<script>
import AppRsvp from '../components/rsvp'

export default {
  name: 'Home',
  components: {
    AppRsvp,
  },
  data() {
    return {
      raw5ry7: ' ',
      raw255a: ' ',
      rawto8q: ' ',
    }
  },
  metaInfo: {
    title: 'wedding-page',
    meta: [
      {
        property: 'og:title',
        content: 'wedding-page',
      },
    ],
  },
}
</script>

<style scoped>
.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.home-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.home-text {
  color: #00a79d;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  font-family: Lobster Two;
  font-weight: 700;
}
.home-nav {
  display: flex;
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-text01 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  border-color: #00a79d;
  border-width: 0px;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 2px;
}
.home-navlink {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink2 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-navlink3 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.home-btn-group {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  flex-direction: row;
  justify-content: space-between;
}
.home-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-arrow {
  transition: 0.3s;
}
.home-icon {
  width: 24px;
  height: 24px;
}
.home-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.home-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.home-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.home-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.home-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.home-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.home-container1 {
  width: 100%;
  height: 544px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-image {
  width: 576px;
  height: 549px;
  align-self: center;
  object-fit: cover;
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  display: flex;
  align-items: flex-start;
}
.home-container3 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
.home-container4 {
  flex: 0 0 auto;
  width: 60%;
  height: 136px;
  display: flex;
  align-items: center;
  padding-top: 21px;
  margin-bottom: 19px;
  flex-direction: column;
}
.home-container5 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text07 {
  color: var(--dl-color-gray-black);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
}
.home-text10 {
  color: rgb(0, 167, 157);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
  border-width: 0px;
  text-decoration: none;
}
.home-container6 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text11 {
  color: var(--dl-color-gray-black);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
}
.home-text12 {
  color: rgb(0, 167, 157);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
  border-width: 0px;
  text-decoration: none;
}
.home-container7 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
.home-text13 {
  color: var(--dl-color-gray-black);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
}
.home-text14 {
  color: rgb(0, 167, 157);
  font-size: 22px;
  text-align: right;
  font-family: Lobster Two;
  border-width: 0px;
  text-decoration: none;
}
.home-container8 {
  flex: 0 0 auto;
  width: 20%;
  height: 100px;
  display: flex;
  align-items: flex-start;
  border-color: var(--dl-color-gray-white);
  border-style: solid;
  border-width: 1px;
  flex-direction: column;
}
@media(max-width: 991px) {
  .home-text {
    color: #00a79d;
    font-style: normal;
    font-weight: 700;
  }
  .home-btn-group {
    border-color: var(--dl-color-gray-black);
    border-style: solid;
  }
  .home-dropdown {
    display: none;
  }
  .home-icon {
    fill: #00a79d;
  }
  .home-container3 {
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 1px;
  }
  .home-container8 {
    border-color: var(--dl-color-gray-white);
    border-style: solid;
    border-width: 1px;
  }
}
@media(max-width: 767px) {
  .home-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-text {
    align-self: stretch;
  }
  .home-nav {
    display: none;
  }
  .home-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .home-text01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .home-btn-group {
    display: none;
  }
  .home-dropdown {
    width: auto;
    display: inline-block;
  }
  .home-dropdown-toggle {
    justify-content: flex-end;
  }
  .home-icon {
    fill: #00a79d;
  }
  .home-dropdown-list {
    display: none;
    position: static;
    border-color: var(--dl-color-gray-white);
  }
  .home-dropdown-toggle1 {
    justify-content: flex-end;
  }
  .home-text02 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-dropdown-toggle2 {
    justify-content: flex-end;
  }
  .home-text03 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-dropdown-toggle3 {
    justify-content: flex-end;
  }
  .home-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-dropdown-toggle4 {
    justify-content: flex-end;
  }
  .home-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-dropdown-toggle5 {
    justify-content: flex-end;
  }
  .home-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-dropdown-toggle6 {
    justify-content: flex-end;
  }
  .home-container3 {
    display: none;
  }
  .home-container4 {
    width: 100%;
  }
  .home-container8 {
    display: none;
  }
}
@media(max-width: 479px) {
  .home-header {
    padding: var(--dl-space-space-unit);
  }
  .home-text {
    align-self: stretch;
  }
  .home-btn-group {
    display: none;
  }
  .home-dropdown-toggle {
    justify-content: flex-end;
  }
  .home-icon {
    fill: #00a79d;
  }
  .home-dropdown-list {
    display: none;
  }
  .home-text02 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-text03 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .home-image {
    width: 436px;
    height: 446px;
  }
  .home-container3 {
    display: none;
  }
  .home-text07 {
    font-size: 18px;
  }
  .home-text10 {
    font-size: 18px;
  }
  .home-text11 {
    font-size: 18px;
  }
  .home-text12 {
    font-size: 18px;
  }
  .home-text13 {
    font-size: 18px;
  }
  .home-text14 {
    font-size: 18px;
  }
  .home-container8 {
    display: none;
  }
}
</style>
