<template>
  <div class="location-container">
    <header data-role="Header" class="location-header">
      <span class="location-text">Marieke &amp; Sven</span>
      <div class="location-nav">
        <nav class="location-nav1">
          <router-link to="/" class="location-text01">Home</router-link>
          <router-link to="/location" class="location-navlink">
            Location
          </router-link>
          <router-link to="/wedding" class="location-navlink1">
            Wedding
          </router-link>
          <router-link to="/the-team" class="location-navlink2">
            The team
          </router-link>
          <router-link to="/additional-info" class="location-navlink3">
            Additional Info
          </router-link>
        </nav>
      </div>
      <div class="location-btn-group"><app-rsvp></app-rsvp></div>
      <div data-thq="thq-dropdown" class="location-dropdown list-item">
        <div data-thq="thq-dropdown-toggle" class="location-dropdown-toggle">
          <div data-thq="thq-dropdown-arrow" class="location-dropdown-arrow">
            <svg viewBox="0 0 1024 1024" class="location-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <ul data-thq="thq-dropdown-list" class="location-dropdown-list">
          <li data-thq="thq-dropdown" class="location-dropdown1 list-item">
            <router-link to="/">
              <div
                data-thq="thq-dropdown-toggle"
                class="location-dropdown-toggle1"
              >
                <span class="location-text02">Home</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="location-dropdown2 list-item">
            <router-link to="/location">
              <div
                data-thq="thq-dropdown-toggle"
                class="location-dropdown-toggle2"
              >
                <span class="location-text03">Location</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="location-dropdown3 list-item">
            <router-link to="/wedding">
              <div
                data-thq="thq-dropdown-toggle"
                class="location-dropdown-toggle3"
              >
                <span class="location-text04">Wedding</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="location-dropdown4 list-item">
            <router-link to="/additional-info">
              <div
                data-thq="thq-dropdown-toggle"
                class="location-dropdown-toggle4"
              >
                <span class="location-text05">Additional Info</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="location-dropdown5 list-item">
            <router-link to="/the-team">
              <div
                data-thq="thq-dropdown-toggle"
                class="location-dropdown-toggle5"
              >
                <span class="location-text06">The team</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="location-dropdown6 list-item">
            <div data-thq="thq-dropdown-toggle" class="location-dropdown-toggle6">
              <app-rsvp rootClassName="rsvp-root-class-name1"></app-rsvp>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="location-container1">
      <span class="location-text07">
        <span>
          Cafe Restaurant Hotel Buiten is een ontspannen en kleurrijke plek ten
          westen van Amsterdam.
        </span>
        <br />
        <span>
          Hier vieren we de hele middag en avond onze bruiloft. Helaas niet
          slapen, want het is geen hotel …
        </span>
        <br />
        <br />
        <span>
          Als de weergoden ons goed gezind zijn, is de ceremonie buiten met
          uitzicht over de Sloterplas.
        </span>
        <br />
        <span>
          En schijnt de zon echt volop? Dan kunnen de buitenmensen onder ons een
          duik nemen bij het Sloterstrand.
        </span>
        <br />
        <span>Bij minder goed weer vieren en dineren we knus binnen.</span>
        <br />
        <br />
        <span>Hotel Buiten</span>
        <br />
        <span>TH J Lammerslaan 3</span>
        <br />
        <span>1064 DH Amsterdam</span>
        <br />
        <br />
        <span>Kom je met de auto?</span>
        <br />
        <span>Parkeren kan om de hoek, gratis, bij het Optis Sloterparkbad.</span>
        <br />
        <span>
          De slagbomen staan de laatste 6 jaar al open, dus als het goed is ook op
          22 april.
        </span>
        <br />
        <span>
          Loop na het parkeren naar de noordkant van het parkeerterrein.
        </span>
        <br />
        <span>
          Ga over het fiets/loop bruggetje, rechtsaf, en je ziet de groene
          zeecontainers van Hotel Buiten. Welkom!
        </span>
        <br />
        <br />
        <span>Kom je met het openbaar vervoer?</span>
        <br />
        <span>Tramhalte Sloterpark met tram 7 is om de hoek.</span>
        <br />
        <span>
          Met een OV fiets vanaf Station Amsterdam Centraal is 22 min of Station
          Sloterdijk 12 min.
        </span>
      </span>
      <img
        alt="image"
        src="/playground_assets/bloem5-200w.png"
        class="location-image"
      />
      <span class="location-text43">
        <span>
          Das Restaurant Hotel Buiten ist ein entspanntes Cafe westlich von
          Amsterdam.
        </span>
        <br />
        <span>
          Hier feiern wir den ganzen Nachmittag und Abend unsere Hochzeit. Leider
          können wir hier nicht schlafen, da es sich nicht um ein Hotel handelt
          ...
        </span>
        <br />
        <br />
        <span>
          Wenn der Wettergott uns wohlgesonnen ist, findet die Zeremonie im Freien
          mit Blick auf den Sloterplas statt.
        </span>
        <br />
        <span>
          Die echten Naturliebhaber unter uns können am Sloterstrand bei
          Sonnenschein auch baden gehen.
        </span>
        <br />
        <span>
          Wenn das Wetter nicht so gut ist, feiern wir und speisen wir gemütlich
          unter dem Glasdach.
        </span>
        <br />
        <br />
        <span>Hotel Buiten</span>
        <br />
        <span>TH J Lammerslaan 3</span>
        <br />
        <span>1064 DH Amsterdam</span>
        <br />
        <br />
        <span>Ihr kommt mit dem Auto?</span>
        <br />
        <span>
          Gleich um die Ecke könnt ihr kostenlos im Optis Sloterparkbad parken.
        </span>
        <br />
        <span>
          Die Schranken sind schon seit sechs Jahren nicht mehr in Betrieb.
          Eigentlich haben die Schranken laut unserer Informationen noch nie
          funktioniert. Also wenn alles gut geht, auch am 22. April....
        </span>
        <br />
        <span>
          Nach dem Parken nutzt ihr die an der Nordseite des Parkplatzes liegende
          Rad-/Gehwegbrücke. Dann rechts abbiegen, und dann könnt ihr schon die
          grünen Schiffscontainer des Hotel Buiten sehen. Herzlich Willkommen!
        </span>
        <br />
        <br />
        <span>Ihr kommt mit den öffentlichen Verkehrsmitteln?</span>
        <br />
        <span>
          Die Straßenbahnhaltestelle Sloterpark der Linie 7 ist gleich um die
          Ecke.
        </span>
        <br />
        <span>
          Mit den öffentlichen Verkehrsmitteln sind es vom Amsterdamer
          Hauptbahnhof 22 Minuten oder vom Bahnhof Sloterdijk 12 Minuten.
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import AppRsvp from '../components/rsvp'

export default {
  name: 'Location',
  components: {
    AppRsvp,
  },
  metaInfo: {
    title: 'Location - wedding-page',
    meta: [
      {
        property: 'og:title',
        content: 'Location - wedding-page',
      },
    ],
  },
}
</script>

<style scoped>
.location-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.location-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.location-text {
  color: #00a79d;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  font-family: Lobster Two;
  font-weight: 700;
}
.location-nav {
  display: flex;
}
.location-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.location-text01 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  border-width: 0px;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.location-navlink {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-color: #00a79d;
  border-width: 0px;
  text-decoration: none;
  border-bottom-width: 2px;
}
.location-navlink1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.location-navlink2 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.location-navlink3 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.location-btn-group {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  flex-direction: row;
  justify-content: space-between;
}
.location-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.location-dropdown-arrow {
  transition: 0.3s;
}
.location-icon {
  width: 24px;
  height: 24px;
}
.location-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.location-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.location-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-text02 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.location-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.location-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-text03 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.location-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.location-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-text04 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.location-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.location-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-text05 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.location-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.location-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-text06 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.location-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.location-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.location-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.location-container1 {
  width: 964px;
  height: 894px;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
}
.location-text07 {
  align-self: center;
  text-align: center;
}
.location-image {
  width: 45px;
  height: 92px;
  object-fit: cover;
}
.location-text43 {
  align-self: center;
  text-align: center;
}
@media(max-width: 991px) {
  .location-text {
    color: #00a79d;
    font-style: normal;
    font-weight: 700;
  }
  .location-btn-group {
    border-color: var(--dl-color-gray-black);
    border-style: solid;
  }
  .location-dropdown {
    display: none;
  }
  .location-icon {
    fill: #00a79d;
  }
  .location-container1 {
    width: 568px;
    height: 595px;
    align-self: center;
  }
}
@media(max-width: 767px) {
  .location-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .location-text {
    align-self: stretch;
  }
  .location-nav {
    display: none;
  }
  .location-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .location-text01 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .location-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .location-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .location-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .location-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .location-btn-group {
    display: none;
  }
  .location-dropdown {
    width: auto;
    display: inline-block;
  }
  .location-dropdown-toggle {
    justify-content: flex-end;
  }
  .location-icon {
    fill: #00a79d;
  }
  .location-dropdown-list {
    display: none;
    position: static;
    border-color: var(--dl-color-gray-white);
  }
  .location-dropdown-toggle1 {
    justify-content: flex-end;
  }
  .location-text02 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-dropdown-toggle2 {
    justify-content: flex-end;
  }
  .location-text03 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-dropdown-toggle3 {
    justify-content: flex-end;
  }
  .location-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-dropdown-toggle4 {
    justify-content: flex-end;
  }
  .location-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-dropdown-toggle5 {
    justify-content: flex-end;
  }
  .location-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-dropdown-toggle6 {
    justify-content: flex-end;
  }
  .location-container1 {
    width: 699px;
    height: 558px;
  }
}
@media(max-width: 479px) {
  .location-header {
    padding: var(--dl-space-space-unit);
  }
  .location-text {
    align-self: stretch;
  }
  .location-btn-group {
    display: none;
  }
  .location-dropdown-toggle {
    justify-content: flex-end;
  }
  .location-icon {
    fill: #00a79d;
  }
  .location-dropdown-list {
    display: none;
  }
  .location-text02 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-text03 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-text04 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-text05 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-text06 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .location-container1 {
    width: 455px;
    height: 617px;
  }
}
</style>
