<template>
  <div class="additional-info-container">
    <header data-role="Header" class="additional-info-header">
      <span class="additional-info-text">Marieke &amp; Sven</span>
      <div class="additional-info-nav">
        <nav class="additional-info-nav1">
          <router-link to="/" class="additional-info-text1">Home</router-link>
          <router-link to="/location" class="additional-info-navlink">
            Location
          </router-link>
          <router-link to="/wedding" class="additional-info-navlink1">
            Wedding
          </router-link>
          <router-link to="/the-team" class="additional-info-navlink2">
            The team
          </router-link>
          <router-link to="/additional-info" class="additional-info-navlink3">
            Additional Info
          </router-link>
        </nav>
      </div>
      <div class="additional-info-btn-group"><app-rsvp></app-rsvp></div>
      <div data-thq="thq-dropdown" class="additional-info-dropdown list-item">
        <div
          data-thq="thq-dropdown-toggle"
          class="additional-info-dropdown-toggle"
        >
          <div
            data-thq="thq-dropdown-arrow"
            class="additional-info-dropdown-arrow"
          >
            <svg viewBox="0 0 1024 1024" class="additional-info-icon">
              <path
                d="M128 256h768v86h-768v-86zM128 554v-84h768v84h-768zM128 768v-86h768v86h-768z"
              ></path>
            </svg>
          </div>
        </div>
        <ul data-thq="thq-dropdown-list" class="additional-info-dropdown-list">
          <li data-thq="thq-dropdown" class="additional-info-dropdown1 list-item">
            <router-link to="/">
              <div
                data-thq="thq-dropdown-toggle"
                class="additional-info-dropdown-toggle1"
              >
                <span class="additional-info-text2">Home</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="additional-info-dropdown2 list-item">
            <router-link to="/location">
              <div
                data-thq="thq-dropdown-toggle"
                class="additional-info-dropdown-toggle2"
              >
                <span class="additional-info-text3">Location</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="additional-info-dropdown3 list-item">
            <router-link to="/wedding">
              <div
                data-thq="thq-dropdown-toggle"
                class="additional-info-dropdown-toggle3"
              >
                <span class="additional-info-text4">Wedding</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="additional-info-dropdown4 list-item">
            <router-link to="/additional-info">
              <div
                data-thq="thq-dropdown-toggle"
                class="additional-info-dropdown-toggle4"
              >
                <span class="additional-info-text5">Additional Info</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="additional-info-dropdown5 list-item">
            <router-link to="/the-team">
              <div
                data-thq="thq-dropdown-toggle"
                class="additional-info-dropdown-toggle5"
              >
                <span class="additional-info-text6">The team</span>
              </div>
            </router-link>
          </li>
          <li data-thq="thq-dropdown" class="additional-info-dropdown6 list-item">
            <div
              data-thq="thq-dropdown-toggle"
              class="additional-info-dropdown-toggle6"
            >
              <app-rsvp rootClassName="rsvp-root-class-name3"></app-rsvp>
            </div>
          </li>
        </ul>
      </div>
    </header>
    <div class="additional-info-container1">
      <span class="additional-info-text7">
        More information to follow - Meer informatie volgt - Mehr Informationen
        folgen
      </span>
    </div>
    <img
      alt="image"
      src="/playground_assets/bloem2-200w.png"
      class="additional-info-image"
    />
  </div>
</template>

<script>
import AppRsvp from '../components/rsvp'

export default {
  name: 'AdditionalInfo',
  components: {
    AppRsvp,
  },
  metaInfo: {
    title: 'Additional-Info - wedding-page',
    meta: [
      {
        property: 'og:title',
        content: 'Additional-Info - wedding-page',
      },
    ],
  },
}
</script>

<style scoped>
.additional-info-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
}
.additional-info-header {
  width: 100%;
  display: flex;
  max-width: var(--dl-size-size-maxwidth);
  align-items: center;
  padding-top: var(--dl-space-space-twounits);
  padding-left: var(--dl-space-space-threeunits);
  padding-right: var(--dl-space-space-threeunits);
  padding-bottom: var(--dl-space-space-twounits);
  justify-content: space-between;
}
.additional-info-text {
  color: #00a79d;
  font-size: 24px;
  align-self: stretch;
  font-style: normal;
  font-family: Lobster Two;
  font-weight: 700;
}
.additional-info-nav {
  display: flex;
}
.additional-info-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: center;
  flex-direction: row;
}
.additional-info-text1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.additional-info-navlink {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
}
.additional-info-navlink1 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-width: 0px;
  text-decoration: none;
}
.additional-info-navlink2 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  text-decoration: none;
}
.additional-info-navlink3 {
  color: #00a79d;
  font-style: normal;
  font-family: GFS Didot;
  font-weight: 400;
  margin-left: var(--dl-space-space-twounits);
  border-color: #00a79d;
  border-width: 0px;
  text-decoration: none;
  border-bottom-width: 2px;
}
.additional-info-btn-group {
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-white);
  border-style: dashed;
  flex-direction: row;
  justify-content: space-between;
}
.additional-info-dropdown {
  cursor: pointer;
  display: none;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius2);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.additional-info-dropdown-arrow {
  transition: 0.3s;
}
.additional-info-icon {
  width: 24px;
  height: 24px;
}
.additional-info-dropdown-list {
  left: 0%;
  width: max-content;
  display: none;
  z-index: 100;
  position: absolute;
  min-width: 100%;
  transition: 0.3s;
  align-items: stretch;
  border-color: #D9D9D9;
  border-width: 1px;
  border-radius: var(--dl-radius-radius-radius4);
  flex-direction: column;
  list-style-type: none;
  background-color: var(--dl-color-gray-white);
  list-style-position: inside;
}
.additional-info-dropdown1 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle1 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.additional-info-dropdown-toggle1:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-text2 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.additional-info-dropdown2 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle2 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.additional-info-dropdown-toggle2:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-text3 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.additional-info-dropdown3 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle3 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.additional-info-dropdown-toggle3:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-text4 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.additional-info-dropdown4 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle4 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.additional-info-dropdown-toggle4:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-text5 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.additional-info-dropdown5 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle5 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
  text-decoration: none;
}
.additional-info-dropdown-toggle5:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-text6 {
  width: 100%;
  cursor: pointer;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
}
.additional-info-dropdown6 {
  cursor: pointer;
  display: inline-block;
  position: relative;
  border-radius: var(--dl-radius-radius-radius2);
}
.additional-info-dropdown-toggle6 {
  fill: #595959;
  color: #595959;
  width: 100%;
  display: inline-flex;
  transition: 0.3s;
  align-items: center;
  padding-top: var(--dl-space-space-halfunit);
  padding-left: var(--dl-space-space-unit);
  border-radius: var(--dl-radius-radius-radius4);
  padding-right: var(--dl-space-space-unit);
  padding-bottom: var(--dl-space-space-halfunit);
}
.additional-info-dropdown-toggle6:hover {
  fill: #fff;
  color: #fff;
  background-color: #595959;
}
.additional-info-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.additional-info-text7 {
  font-size: 19px;
  align-self: center;
  text-align: center;
  font-family: Lobster Two;
}
.additional-info-image {
  width: 100px;
  object-fit: cover;
}
@media(max-width: 991px) {
  .additional-info-text {
    color: #00a79d;
    font-style: normal;
    font-weight: 700;
  }
  .additional-info-btn-group {
    border-color: var(--dl-color-gray-black);
    border-style: solid;
  }
  .additional-info-dropdown {
    display: none;
  }
  .additional-info-icon {
    fill: #00a79d;
  }
}
@media(max-width: 767px) {
  .additional-info-header {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .additional-info-text {
    align-self: stretch;
  }
  .additional-info-nav {
    display: none;
  }
  .additional-info-nav1 {
    align-items: flex-start;
    flex-direction: column;
  }
  .additional-info-text1 {
    margin-bottom: var(--dl-space-space-unit);
  }
  .additional-info-navlink {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .additional-info-navlink1 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .additional-info-navlink2 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .additional-info-navlink3 {
    margin-left: 0;
    margin-bottom: var(--dl-space-space-unit);
  }
  .additional-info-btn-group {
    display: none;
  }
  .additional-info-dropdown {
    width: auto;
    display: inline-block;
  }
  .additional-info-dropdown-toggle {
    justify-content: flex-end;
  }
  .additional-info-icon {
    fill: #00a79d;
  }
  .additional-info-dropdown-list {
    display: none;
    position: static;
    border-color: var(--dl-color-gray-white);
  }
  .additional-info-dropdown-toggle1 {
    justify-content: flex-end;
  }
  .additional-info-text2 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-dropdown-toggle2 {
    justify-content: flex-end;
  }
  .additional-info-text3 {
    color: #00a79d;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-dropdown-toggle3 {
    justify-content: flex-end;
  }
  .additional-info-text4 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-dropdown-toggle4 {
    justify-content: flex-end;
  }
  .additional-info-text5 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-dropdown-toggle5 {
    justify-content: flex-end;
  }
  .additional-info-text6 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    align-items: center;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-dropdown-toggle6 {
    justify-content: flex-end;
  }
}
@media(max-width: 479px) {
  .additional-info-header {
    padding: var(--dl-space-space-unit);
  }
  .additional-info-text {
    align-self: stretch;
  }
  .additional-info-btn-group {
    display: none;
  }
  .additional-info-dropdown-toggle {
    justify-content: flex-end;
  }
  .additional-info-icon {
    fill: #00a79d;
  }
  .additional-info-dropdown-list {
    display: none;
  }
  .additional-info-text2 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-text3 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-text4 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-text5 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-text6 {
    color: rgb(0, 167, 157);
    font-size: 14px;
    font-style: normal;
    text-align: right;
    font-family: Arial;
    font-weight: 400;
    justify-content: flex-end;
  }
  .additional-info-text7 {
    font-size: 16px;
    align-self: center;
    text-align: center;
    font-family: Lobster Two;
  }
}
</style>
